/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SignUpTrialIn model module.
 * @module model/SignUpTrialIn
 * @version 1.0.0
 */
class SignUpTrialIn {
    /**
     * Constructs a new <code>SignUpTrialIn</code>.
     * @alias module:model/SignUpTrialIn
     * @param username {String} 
     * @param email {String} 
     * @param password {String} 
     * @param organizationName {String} 
     */
    constructor(username, email, password, organizationName) { 
        
        SignUpTrialIn.initialize(this, username, email, password, organizationName);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, username, email, password, organizationName) { 
        obj['username'] = username;
        obj['email'] = email;
        obj['password'] = password;
        obj['organization_name'] = organizationName;
    }

    /**
     * Constructs a <code>SignUpTrialIn</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SignUpTrialIn} obj Optional instance to populate.
     * @return {module:model/SignUpTrialIn} The populated <code>SignUpTrialIn</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SignUpTrialIn();

            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('captcha_token')) {
                obj['captcha_token'] = ApiClient.convertToType(data['captcha_token'], 'String');
            }
            if (data.hasOwnProperty('organization_name')) {
                obj['organization_name'] = ApiClient.convertToType(data['organization_name'], 'String');
            }
            if (data.hasOwnProperty('organization_code')) {
                obj['organization_code'] = ApiClient.convertToType(data['organization_code'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SignUpTrialIn</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SignUpTrialIn</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of SignUpTrialIn.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['username'] && !(typeof data['username'] === 'string' || data['username'] instanceof String)) {
            throw new Error("Expected the field `username` to be a primitive type in the JSON string but got " + data['username']);
        }
        // ensure the json data is a string
        if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
            throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
        }
        // ensure the json data is a string
        if (data['password'] && !(typeof data['password'] === 'string' || data['password'] instanceof String)) {
            throw new Error("Expected the field `password` to be a primitive type in the JSON string but got " + data['password']);
        }
        // ensure the json data is a string
        if (data['captcha_token'] && !(typeof data['captcha_token'] === 'string' || data['captcha_token'] instanceof String)) {
            throw new Error("Expected the field `captcha_token` to be a primitive type in the JSON string but got " + data['captcha_token']);
        }
        // ensure the json data is a string
        if (data['organization_name'] && !(typeof data['organization_name'] === 'string' || data['organization_name'] instanceof String)) {
            throw new Error("Expected the field `organization_name` to be a primitive type in the JSON string but got " + data['organization_name']);
        }
        // ensure the json data is a string
        if (data['organization_code'] && !(typeof data['organization_code'] === 'string' || data['organization_code'] instanceof String)) {
            throw new Error("Expected the field `organization_code` to be a primitive type in the JSON string but got " + data['organization_code']);
        }

        return true;
    }


}

SignUpTrialIn.RequiredProperties = ["username", "email", "password", "organization_name"];

/**
 * @member {String} username
 */
SignUpTrialIn.prototype['username'] = undefined;

/**
 * @member {String} email
 */
SignUpTrialIn.prototype['email'] = undefined;

/**
 * @member {String} password
 */
SignUpTrialIn.prototype['password'] = undefined;

/**
 * @member {String} captcha_token
 */
SignUpTrialIn.prototype['captcha_token'] = undefined;

/**
 * @member {String} organization_name
 */
SignUpTrialIn.prototype['organization_name'] = undefined;

/**
 * @member {String} organization_code
 */
SignUpTrialIn.prototype['organization_code'] = undefined;






export default SignUpTrialIn;

