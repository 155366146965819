/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The RefreshAccessTokenOut model module.
 * @module model/RefreshAccessTokenOut
 * @version 1.0.0
 */
class RefreshAccessTokenOut {
    /**
     * Constructs a new <code>RefreshAccessTokenOut</code>.
     * @alias module:model/RefreshAccessTokenOut
     * @param accessToken {String} 
     * @param expiresIn {Number} 
     * @param refreshToken {String} 
     */
    constructor(accessToken, expiresIn, refreshToken) { 
        
        RefreshAccessTokenOut.initialize(this, accessToken, expiresIn, refreshToken);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, accessToken, expiresIn, refreshToken) { 
        obj['access_token'] = accessToken;
        obj['expires_in'] = expiresIn;
        obj['refresh_token'] = refreshToken;
    }

    /**
     * Constructs a <code>RefreshAccessTokenOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RefreshAccessTokenOut} obj Optional instance to populate.
     * @return {module:model/RefreshAccessTokenOut} The populated <code>RefreshAccessTokenOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RefreshAccessTokenOut();

            if (data.hasOwnProperty('access_token')) {
                obj['access_token'] = ApiClient.convertToType(data['access_token'], 'String');
            }
            if (data.hasOwnProperty('expires_in')) {
                obj['expires_in'] = ApiClient.convertToType(data['expires_in'], 'Number');
            }
            if (data.hasOwnProperty('refresh_token')) {
                obj['refresh_token'] = ApiClient.convertToType(data['refresh_token'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>RefreshAccessTokenOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RefreshAccessTokenOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of RefreshAccessTokenOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['access_token'] && !(typeof data['access_token'] === 'string' || data['access_token'] instanceof String)) {
            throw new Error("Expected the field `access_token` to be a primitive type in the JSON string but got " + data['access_token']);
        }
        // ensure the json data is a string
        if (data['refresh_token'] && !(typeof data['refresh_token'] === 'string' || data['refresh_token'] instanceof String)) {
            throw new Error("Expected the field `refresh_token` to be a primitive type in the JSON string but got " + data['refresh_token']);
        }

        return true;
    }


}

RefreshAccessTokenOut.RequiredProperties = ["access_token", "expires_in", "refresh_token"];

/**
 * @member {String} access_token
 */
RefreshAccessTokenOut.prototype['access_token'] = undefined;

/**
 * @member {Number} expires_in
 */
RefreshAccessTokenOut.prototype['expires_in'] = undefined;

/**
 * @member {String} refresh_token
 */
RefreshAccessTokenOut.prototype['refresh_token'] = undefined;






export default RefreshAccessTokenOut;

