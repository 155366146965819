/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CheckLinkResetForgottenPasswordIn from '../model/CheckLinkResetForgottenPasswordIn';
import CheckUserInviteLinkIn from '../model/CheckUserInviteLinkIn';
import ForgotPasswordIn from '../model/ForgotPasswordIn';
import GenerateAccessTokenIn from '../model/GenerateAccessTokenIn';
import GenerateAccessTokenOut from '../model/GenerateAccessTokenOut';
import LoginIn from '../model/LoginIn';
import RefreshAccessTokenIn from '../model/RefreshAccessTokenIn';
import RefreshAccessTokenOut from '../model/RefreshAccessTokenOut';
import ResetForgottenPasswordIn from '../model/ResetForgottenPasswordIn';
import RevokeRefreshTokenIn from '../model/RevokeRefreshTokenIn';
import SignUpTrialIn from '../model/SignUpTrialIn';
import SignUpTrialOut from '../model/SignUpTrialOut';
import UserActivateIn from '../model/UserActivateIn';
import UserInviteIn from '../model/UserInviteIn';
import UserSignUpIn from '../model/UserSignUpIn';
import UserSignUpOut from '../model/UserSignUpOut';

/**
* Auth service.
* @module api/AuthApi
* @version 1.0.0
*/
export default class AuthApi {

    /**
    * Constructs a new AuthApi. 
    * @alias module:api/AuthApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     *  
     * @param {module:model/CheckLinkResetForgottenPasswordIn} checkLinkResetForgottenPasswordIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    checkLinkResetPasswordWithHttpInfo(checkLinkResetForgottenPasswordIn) {
      let postBody = checkLinkResetForgottenPasswordIn;
      // verify the required parameter 'checkLinkResetForgottenPasswordIn' is set
      if (checkLinkResetForgottenPasswordIn === undefined || checkLinkResetForgottenPasswordIn === null) {
        throw new Error("Missing the required parameter 'checkLinkResetForgottenPasswordIn' when calling checkLinkResetPassword");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/auth/password/reset/link', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/CheckLinkResetForgottenPasswordIn} checkLinkResetForgottenPasswordIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    checkLinkResetPassword(checkLinkResetForgottenPasswordIn) {
      return this.checkLinkResetPasswordWithHttpInfo(checkLinkResetForgottenPasswordIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/CheckUserInviteLinkIn} checkUserInviteLinkIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    checkUserInviteLinkWithHttpInfo(checkUserInviteLinkIn) {
      let postBody = checkUserInviteLinkIn;
      // verify the required parameter 'checkUserInviteLinkIn' is set
      if (checkUserInviteLinkIn === undefined || checkUserInviteLinkIn === null) {
        throw new Error("Missing the required parameter 'checkUserInviteLinkIn' when calling checkUserInviteLink");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/auth/user-invite/link', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/CheckUserInviteLinkIn} checkUserInviteLinkIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    checkUserInviteLink(checkUserInviteLinkIn) {
      return this.checkUserInviteLinkWithHttpInfo(checkUserInviteLinkIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/ForgotPasswordIn} forgotPasswordIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    forgotPasswordWithHttpInfo(forgotPasswordIn) {
      let postBody = forgotPasswordIn;
      // verify the required parameter 'forgotPasswordIn' is set
      if (forgotPasswordIn === undefined || forgotPasswordIn === null) {
        throw new Error("Missing the required parameter 'forgotPasswordIn' when calling forgotPassword");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/auth/forgot-password', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/ForgotPasswordIn} forgotPasswordIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    forgotPassword(forgotPasswordIn) {
      return this.forgotPasswordWithHttpInfo(forgotPasswordIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/GenerateAccessTokenIn} generateAccessTokenIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GenerateAccessTokenOut} and HTTP response
     */
    generateAccessTokenWithHttpInfo(generateAccessTokenIn) {
      let postBody = generateAccessTokenIn;
      // verify the required parameter 'generateAccessTokenIn' is set
      if (generateAccessTokenIn === undefined || generateAccessTokenIn === null) {
        throw new Error("Missing the required parameter 'generateAccessTokenIn' when calling generateAccessToken");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = GenerateAccessTokenOut;
      return this.apiClient.callApi(
        '/api/token/access', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/GenerateAccessTokenIn} generateAccessTokenIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GenerateAccessTokenOut}
     */
    generateAccessToken(generateAccessTokenIn) {
      return this.generateAccessTokenWithHttpInfo(generateAccessTokenIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/LoginIn} loginIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    loginWithHttpInfo(loginIn) {
      let postBody = loginIn;
      // verify the required parameter 'loginIn' is set
      if (loginIn === undefined || loginIn === null) {
        throw new Error("Missing the required parameter 'loginIn' when calling login");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/auth/login', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/LoginIn} loginIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    login(loginIn) {
      return this.loginWithHttpInfo(loginIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    logoutWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/auth/logout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    logout() {
      return this.logoutWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/RefreshAccessTokenIn} refreshAccessTokenIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RefreshAccessTokenOut} and HTTP response
     */
    refreshAccessTokenWithHttpInfo(refreshAccessTokenIn) {
      let postBody = refreshAccessTokenIn;
      // verify the required parameter 'refreshAccessTokenIn' is set
      if (refreshAccessTokenIn === undefined || refreshAccessTokenIn === null) {
        throw new Error("Missing the required parameter 'refreshAccessTokenIn' when calling refreshAccessToken");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = RefreshAccessTokenOut;
      return this.apiClient.callApi(
        '/api/token/refresh', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/RefreshAccessTokenIn} refreshAccessTokenIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RefreshAccessTokenOut}
     */
    refreshAccessToken(refreshAccessTokenIn) {
      return this.refreshAccessTokenWithHttpInfo(refreshAccessTokenIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/ResetForgottenPasswordIn} resetForgottenPasswordIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    resetPasswordWithHttpInfo(resetForgottenPasswordIn) {
      let postBody = resetForgottenPasswordIn;
      // verify the required parameter 'resetForgottenPasswordIn' is set
      if (resetForgottenPasswordIn === undefined || resetForgottenPasswordIn === null) {
        throw new Error("Missing the required parameter 'resetForgottenPasswordIn' when calling resetPassword");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/auth/password/reset', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/ResetForgottenPasswordIn} resetForgottenPasswordIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    resetPassword(resetForgottenPasswordIn) {
      return this.resetPasswordWithHttpInfo(resetForgottenPasswordIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/RevokeRefreshTokenIn} revokeRefreshTokenIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    revokeRefreshTokenWithHttpInfo(revokeRefreshTokenIn) {
      let postBody = revokeRefreshTokenIn;
      // verify the required parameter 'revokeRefreshTokenIn' is set
      if (revokeRefreshTokenIn === undefined || revokeRefreshTokenIn === null) {
        throw new Error("Missing the required parameter 'revokeRefreshTokenIn' when calling revokeRefreshToken");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/token/revoke', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/RevokeRefreshTokenIn} revokeRefreshTokenIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    revokeRefreshToken(revokeRefreshTokenIn) {
      return this.revokeRefreshTokenWithHttpInfo(revokeRefreshTokenIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/UserSignUpIn} userSignUpIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserSignUpOut} and HTTP response
     */
    signupWithHttpInfo(userSignUpIn) {
      let postBody = userSignUpIn;
      // verify the required parameter 'userSignUpIn' is set
      if (userSignUpIn === undefined || userSignUpIn === null) {
        throw new Error("Missing the required parameter 'userSignUpIn' when calling signup");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UserSignUpOut;
      return this.apiClient.callApi(
        '/api/auth/signup', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/UserSignUpIn} userSignUpIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserSignUpOut}
     */
    signup(userSignUpIn) {
      return this.signupWithHttpInfo(userSignUpIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/SignUpTrialIn} signUpTrialIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SignUpTrialOut} and HTTP response
     */
    signupTrialWithHttpInfo(signUpTrialIn) {
      let postBody = signUpTrialIn;
      // verify the required parameter 'signUpTrialIn' is set
      if (signUpTrialIn === undefined || signUpTrialIn === null) {
        throw new Error("Missing the required parameter 'signUpTrialIn' when calling signupTrial");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = SignUpTrialOut;
      return this.apiClient.callApi(
        '/api/auth/signup/trial', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/SignUpTrialIn} signUpTrialIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SignUpTrialOut}
     */
    signupTrial(signUpTrialIn) {
      return this.signupTrialWithHttpInfo(signUpTrialIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/UserActivateIn} userActivateIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    userActivateWithHttpInfo(userActivateIn) {
      let postBody = userActivateIn;
      // verify the required parameter 'userActivateIn' is set
      if (userActivateIn === undefined || userActivateIn === null) {
        throw new Error("Missing the required parameter 'userActivateIn' when calling userActivate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/auth/user-activate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/UserActivateIn} userActivateIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    userActivate(userActivateIn) {
      return this.userActivateWithHttpInfo(userActivateIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/UserInviteIn} userInviteIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    userInviteWithHttpInfo(userInviteIn) {
      let postBody = userInviteIn;
      // verify the required parameter 'userInviteIn' is set
      if (userInviteIn === undefined || userInviteIn === null) {
        throw new Error("Missing the required parameter 'userInviteIn' when calling userInvite");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/auth/user-invite', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/UserInviteIn} userInviteIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    userInvite(userInviteIn) {
      return this.userInviteWithHttpInfo(userInviteIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
