/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UserInviteIn model module.
 * @module model/UserInviteIn
 * @version 1.0.0
 */
class UserInviteIn {
    /**
     * Constructs a new <code>UserInviteIn</code>.
     * @alias module:model/UserInviteIn
     * @param username {String} 
     * @param password {String} 
     * @param token {String} 
     * @param verifier {String} 
     */
    constructor(username, password, token, verifier) { 
        
        UserInviteIn.initialize(this, username, password, token, verifier);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, username, password, token, verifier) { 
        obj['username'] = username;
        obj['password'] = password;
        obj['token'] = token;
        obj['verifier'] = verifier;
    }

    /**
     * Constructs a <code>UserInviteIn</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserInviteIn} obj Optional instance to populate.
     * @return {module:model/UserInviteIn} The populated <code>UserInviteIn</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserInviteIn();

            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('token')) {
                obj['token'] = ApiClient.convertToType(data['token'], 'String');
            }
            if (data.hasOwnProperty('verifier')) {
                obj['verifier'] = ApiClient.convertToType(data['verifier'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UserInviteIn</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserInviteIn</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of UserInviteIn.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['username'] && !(typeof data['username'] === 'string' || data['username'] instanceof String)) {
            throw new Error("Expected the field `username` to be a primitive type in the JSON string but got " + data['username']);
        }
        // ensure the json data is a string
        if (data['password'] && !(typeof data['password'] === 'string' || data['password'] instanceof String)) {
            throw new Error("Expected the field `password` to be a primitive type in the JSON string but got " + data['password']);
        }
        // ensure the json data is a string
        if (data['token'] && !(typeof data['token'] === 'string' || data['token'] instanceof String)) {
            throw new Error("Expected the field `token` to be a primitive type in the JSON string but got " + data['token']);
        }
        // ensure the json data is a string
        if (data['verifier'] && !(typeof data['verifier'] === 'string' || data['verifier'] instanceof String)) {
            throw new Error("Expected the field `verifier` to be a primitive type in the JSON string but got " + data['verifier']);
        }

        return true;
    }


}

UserInviteIn.RequiredProperties = ["username", "password", "token", "verifier"];

/**
 * @member {String} username
 */
UserInviteIn.prototype['username'] = undefined;

/**
 * @member {String} password
 */
UserInviteIn.prototype['password'] = undefined;

/**
 * @member {String} token
 */
UserInviteIn.prototype['token'] = undefined;

/**
 * @member {String} verifier
 */
UserInviteIn.prototype['verifier'] = undefined;






export default UserInviteIn;

